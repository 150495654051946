import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appSelector from "../../modules/app/appSelectors";
import authSelector from "../../modules/auth/authSelectors";
import { Button, message, Modal, Popconfirm, Select, Space, Table, Switch, Menu, Input } from "antd";
import NewRequest from "../../components/NewRequest";
import React, { createContext, useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import appActions from "../../modules/app/appActions";
import moment from "moment";
const Pets = (props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const user = useSelector(appSelector.selectUser)
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const positions = useSelector(appSelector.selectPositions)
  const pets = useSelector(appSelector.selectPets)
  const ReachableContext = createContext(null);
  const [modal, contextHolder] = Modal.useModal();
  const { Option } = Select;
  const [isModalOpen, openAddModal] = useState(false)
  const [petsData, setPetsData] = useState([]);
  const [dataChanged, setDataChanged] = useState(false)
  const [petData, setPetData] = useState(null)
  const [withdrawModal, setwithdrawModal] = useState(null);
  
  const items = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          1st menu item
        </a>
      ),
    }];
  
  const positionColumns = [
    {
      title: 'Pet Name',
      dataIndex: 'Pet_Name',
      key: 'Pet_Name'
    },
    {
      title: 'Age',
      dataIndex: 'Pet_Age',
      key: 'Pet_Age'
    },
    {
      title: 'Policy',
      dataIndex: 'Pet_policy',
      key: 'Pet_policy',
      render: (text, record, index) => (
        <Select
          value={record.Pet_policy}
          style={{ width: 120 }}
          disabled={record.withdrawal_method === pets[index].withdrawal_method && pets[index].withdrawal_method === 'once'}
          onChange={(value) =>{
            const data = [...petsData];
            if(data[index].Pet_policy) {
              showPaymentConfirmation(value, data[index].withdrawal_method,
                () => {
                  data[index].Pet_policy = value;
                  setPetsData(data);
                  setDataChanged(true);
                },
                () => {
                  data[index].withdrawal_method = pets[index].withdrawal_method;
                  data[index].Pet_policy = pets[index].Pet_policy;
                  setPetsData(data);
                }
              );
            }else {
              data[index].Pet_policy = value;
              setPetsData(data);
            }
          }}
        >
          <Option value={10}>Classic</Option>
          <Option value={15}>Core</Option>
          <Option value={20}>Premium</Option>
        </Select>
      ),
    },
    {
      title: 'Withdrawal Method',
      dataIndex: 'withdrawal_method',
      key: 'withdrawal_method',
      render: (text, record, index) => (
        <Select
          value={record.withdrawal_method}
          style={{ width: 240 }}
          disabled={record.withdrawal_method === pets[index].withdrawal_method && pets[index].withdrawal_method === 'once'}
          onChange={(value) =>{
            const data = [...petsData];
            if(data[index].Pet_policy) {
              showPaymentConfirmation(data[index].Pet_policy, value,
                () => {
                  data[index].withdrawal_method = value;
                  setPetsData(data);
                  setDataChanged(true);
                },
                () => {
                  data[index].withdrawal_method = pets[index].withdrawal_method;
                  data[index].Pet_policy = pets[index].Pet_policy;
                  setPetsData(data);
                }
              );
            }else {
              data[index].withdrawal_method = value;
              setPetsData(data);
            }
          }}
        >
          <Option value={'monthly'}>Per month (60mos)</Option>
          <Option value={'once'}>Once - in full</Option>
        </Select>
      ),
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record, index) =>
        <Space size="middle">
          <Popconfirm title="Are you sure?" onConfirm={() => handleFundingChange(record.id, index)}>
            <Switch checkedChildren="Funding" unCheckedChildren="Funding" checked={record.funding !== false}  />
          </Popconfirm>
          <Popconfirm title="Are you sure?" onConfirm={() => handleTradingChange(record.id, index)}>
            <Switch checkedChildren="Trading" unCheckedChildren="Trading" checked={record.trading !== false}  />
          </Popconfirm>
          <Button
            onClick={()=>{
              setPetData(petsData[index])
              openAddModal(true);
            }}
            type={'link'}>Edit</Button>
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
            <Button type={'link'}>Delete</Button>
          </Popconfirm>
          
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              id="dropdown-custom-toggle"
              style={{
                border: "none",
                background: "none",
                fontSize: "1.5rem",
                padding: "0",
                color: "black",
              }}
              className="no-caret"
            >
              ⫶
            </Dropdown.Toggle>
            
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>onRestart(record.id, index)}>Restart</Dropdown.Item>
              <Dropdown.Item
                onClick={()=>{
                  setwithdrawModal({visible: true, id: record.id, index: index, percent: ''})
                }}
              >WithDraw</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Space>
      
    },
  ]
  
  const applyChanges = async ()=>{
    const objectsWithoutAge = petsData.filter(obj => !obj.Pet_policy || !obj.withdrawal_method);
    if(objectsWithoutAge?.length>0){
      message.error('Please select all the options');
      return;
    }
    const newData = [...petsData];
    const oldData = [...pets];
    
    const areObjectsEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };
    
    let amount = 0;
    newData.forEach((newItem,index) => {
       const isEqual = areObjectsEqual(newItem, oldData[index])
       if(!isEqual && !oldData[index]?.Pet_policy && newItem.funding !== false){
         if(newItem?.withdrawal_method === 'once'){
            amount += (newItem.Pet_policy * 60);
         }else {
            amount += newItem.Pet_policy;
         }
       }
    });
    if(amount>0){
      Modal.confirm({
        title: 'Are you sure you want to make these changes?',
        content: (
          <div>
            <p>{`Note: Bank account will be debited with ${amount}`}</p>
          </div>
        ),
        onOk() {
          dispatch(appActions.createTransfer(user.accountId, amount, user.id)).then(()=>{
            //message.success('Successfully activated your policy');
            dispatch(appActions.updatePets(petsData)).then(()=>{
              message.success('Changes Applied successfully');
            })
          }).catch((e)=>{
            message.error(e);
          })
        },
        onCancel() {
          console.log('OK');
        },
      });
    }
    else {
      Modal.confirm({
        title: 'Are you sure you want to make these changes?',
        content: (
          <div>
            <p>{`Note: These changes will appear in next month debit`}</p>
          </div>
        ),
        onOk() {
          dispatch(appActions.updatePets(petsData)).then(()=>{
            message.success('Changes Applied successfully');
          })
        },
        onCancel() {
          console.log('OK');
        },
      });
    }
  }
  const handleDelete = async (id)=>{
    dispatch(appActions.deletePet(id)).then(()=>{
      message.success('Pet deleted successfully');
      dispatch(appActions.getPets(currentUser?.uid))
    })
  }
  const onRestart = async (id, index)=>{
    Modal.confirm({
      title: 'Are you sure you want to restart the policy?',
      content: (
        <div>
          <p>{ `Notice: This will reset the start date of this policy.`}</p>
        </div>
      ),
      onOk:()=>{
        const data = [...petsData];
        data[index].startDate = moment.now();
        setPetsData(data);
        setDataChanged(true);
      },
      onCancel:()=>{},
    });
  }
  
  const handleFundingChange = async (id, index)=>{
    const data = [...petsData];
    data[index].funding = data[index].funding === false;
    setPetsData(data);
    setDataChanged(true);
  }
  
  const handleTradingChange = async (id, index)=>{
    const data = [...petsData];
    data[index].trading = data[index].trading === false;
    setPetsData(data);
    setDataChanged(true);
  }
  
  const showPaymentConfirmation = (amount, duration, onOk, onCancel)=>{
    if(!amount || !duration)
      return;
    Modal.confirm({
      title: 'Are you sure you want to make these changes?',
      content: (
        <div>
          <p>{duration === 'monthly' ? `Notice: Bank account will be debited $${amount} every month for 60 months` : `Notice: Bank account will be debited $${amount * 60}`}</p>
        </div>
      ),
      onOk,
      onCancel,
    });
  }
  
  const handleOk = () => {
    openAddModal(false)
    setPetData(null);
    dispatch(appActions.getPets(currentUser?.uid))
  };
  
  const areArraysEqual = (arr1, arr2) =>{
    if (arr1.length !== arr2.length) return false;
    
    return arr1.every((obj1, index) => {
      const obj2 = arr2[index];
      // Check if both have the same keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      
      // Check if all keys and values are identical
      return keys1.every(key => obj1[key] === obj2[key]);
    });
  }
  
  const handleCancel = () => {
    openAddModal(false)
    setPetData(null);
  };
  
  useEffect(() => {
    const data = JSON.parse(JSON.stringify(pets));
    setPetsData(data);
  }, [pets]);
  
  return (
      <div>
        <div>
          <Button
            onClick={() => {
              setPetData({'aa':'aa' });
              openAddModal(true)
            }}
            type="primary" className="mb-2" style={{ float: "right" , backgroundColor:'#2060C4', borderColor: '#2060C4'}}>Add New +</Button>
          {dataChanged &&
            <Popconfirm
              title="Are you sure you wan to apply these changes ?"
              description="Are you sure you wan to apply these changes ?"
              onConfirm={applyChanges}
              okText="Yes"
              cancelText="No"
              placement="bottomLeft"
            >
              <Button
                className="mb-2" style={{ float: "right", marginRight:10, backgroundColor: '#cb4335', color: '#fff' }}>Apply Changes</Button>
            </Popconfirm>
          }
        </div>
        <Table
          columns={positionColumns}
          dataSource={petsData}
        />
        <Modal
          width={650}
          style={{ width: 600 }}
          visible={isModalOpen}
          title="Add Pet"
          footer={null}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <NewRequest
            petData={petData}
            onFinish={() => handleOk()}/>
        </Modal>
        
        <Modal
          title="Input Modal"
          visible={withdrawModal}
          onOk={()=>{
            setwithdrawModal(null);
          }}
          onCancel={()=>{
            setwithdrawModal(null);
          }}
          okText="Save"
          cancelText="Cancel"
        >
          <Input
            type={'number'}
            placeholder="Enter % to withdraw"
            //value={withdrawModal?.percent}
            onChange={(e) => {
              if(e.target.value < 0 || e.target.value > 100) {
                Modal.error({title: 'Invalid number',
                  content: (
                    <div>
                      <p>Please enter value between 0 to 100</p>
                    </div>
                  )
                })
              }
              
              const _withdrawModal = {...withdrawModal};
              _withdrawModal.percent = e.target.value
              //setwithdrawModal(_withdrawModal);
              
            }}
          />
        </Modal>
    </div>
   )
}
export default Pets;
