/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Table } from "antd";
import { useSelector } from "react-redux";
import appSelector from "../../modules/app/appSelectors";

const AccountHeader: React.FC = () => {
  const user = useSelector(appSelector.selectUser)
  const account = useSelector(appSelector.selectAccount)
  const location = useLocation()
  const pets = useSelector(appSelector.selectPets)
  const positionColumns = [
    {
      title: 'Pet Name',
      dataIndex: 'Pet_Name',
      key: 'Pet_Name'
    },
    {
      title: 'Cash Balance',
      dataIndex: 'cash_balance',
      key: 'cash_balance',
      render: (text:any) => <span>{text ||0}</span>
    },
    {
      title: 'Total Cost Basic ($)',
      dataIndex: 'amount_invested',
      key: 'amount_invested',
      render: (text:any) => <span>{text ||0}</span>
    },
    {
      title: 'Total Market Value',
      dataIndex: 'current_value',
      key: 'current_value',
      render: (text:any) => <span>{text ||0}</span>
    },
    {
      title: 'Total Gain/Loss ($)',
      dataIndex: 'change',
      key: 'change',
      render: (text:any) => <span>{text ||0}</span>
    },
    {
      title: 'Total Gain/Loss (%)',
      dataIndex: 'change',
      key: 'change',
      render: (text:any) => <span>{text ||0}</span>
    },
  ]
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <Avatar size={100} icon={<UserOutlined/>}/>
              <div
                className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2 ml-30'>
                  <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {user ? `${user.name}` : ''}
                  </a>
                  <a href='#'>
                    {/*<KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />*/}
                  </a>
                  <a
                    className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_upgrade_plan'
                  >
                    {account && account.status ? account.status.toUpperCase() :''}
                  </a>
                </div>
              </div>

              <div className='d-flex my-4'>

                <div className='me-0'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Table
          columns={positionColumns}
          dataSource={pets}
        />
      </div>
    </div>
  )
}

export { AccountHeader }
